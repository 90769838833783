import { breakpoints, cartBreakpoints, thankYouBreakpoints } from './constants';

export const generateBreakpoints = (type: 'DEFAULT' | 'CART' | 'THANK_YOU') => {
  switch (type) {
    case 'CART':
      return cartBreakpoints;
    case 'THANK_YOU':
      return thankYouBreakpoints;
    default:
      return breakpoints;
  }
};
