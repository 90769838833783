import Link from 'next/link';
import Image from 'next/future/image';
import cn from 'classnames';
import BackIcon from '@/public/icons/product/back.svg';
import { RenderedLinkProps } from './types';

const RenderedLink = ({ link, title, isMobile }: RenderedLinkProps) => (
  <Link href={link} passHref>
    <a className="flex items-center capitalize">
      {isMobile && (
        <div className="h-4 w-4">
          <Image
            src={BackIcon}
            alt="Back"
            className="h-full w-full bg-contain"
            loading="eager"
          />
        </div>
      )}
      <span
        className={cn(' underline underline-offset-1 ', {
          'pl-2': isMobile,
          'tranisition text-gray-700/50 duration-150 ease-in-out hover:text-gray-700/100':
            !isMobile,
        })}
      >
        {title}
      </span>
    </a>
  </Link>
);

export default RenderedLink;
