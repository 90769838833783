import Slider from '@/components/slider/Slider';
import Card from './Card';
import { generateBreakpoints } from './helpers';
import { CarouselProps } from './types';

const Carousel = ({
  popularCategories,
  small,
  type = 'DEFAULT',
}: CarouselProps) => {
  const breakpoints = generateBreakpoints(type);

  return (
    <div className="relative mt-6">
      <Slider breakpoints={breakpoints}>
        {popularCategories.map((category) => (
          <Card
            key={category.handle ?? category.pk}
            category={category}
            small={small}
          />
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
