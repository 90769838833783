import { ParsedUrlQuery } from 'querystring';
import { isEmpty } from 'lodash-es';
import { ReebeloConditions } from '@lambda/reebelo';
import {
  ReplacedProduct,
  SelectionT,
  tagToSelection,
} from '../collections/catalogue/helpers';

export type TopDealProduct = {
  gid: string;
  title: string;
  price: number;
  comparePrice: number | undefined;
  condition: ReebeloConditions | undefined;
  productType: string;
  imageUrl: string;
  psku: string;
  sequence: number;
  tags: string[];
  handle: string;
  vendor: string;
  sku: string;
  color: string;
  brand: string;
  carrier: string | undefined;
  stock: number;
  storage: string | undefined;
  productName: string;
  categoryPks: string[];
};

export const extractIsDealFromTags = (tags: string[]) =>
  !!Object.values(tags).find((tag) => tag.match(/product_is_deal/));

export const OfferDealMap = (
  offers: ReplacedProduct[],
  query: ParsedUrlQuery,
  currentOffer: ReplacedProduct,
) => {
  const VARIANTS = [
    'serviceProvider',
    'screenSize',
    'connectivity',
    'storage',
    'color',
    'cpu',
    'ram',
    'simSlot',
    'charger',
    'touchbar',
    'version',
    'language',
    'watchCaseMaterial',
    'watchFaceSize',
    'watchCaseColor',
    'watchBandMaterial',
    'watchBandType',
    'watchBandColor',
  ];

  const queryLength = Object.keys(query).length;
  const variantsKeys =
    queryLength > 1 ? query : tagToSelection(currentOffer.tags);
  const variants = queryLength > 1 ? query : tagToSelection(currentOffer.tags);

  const matchedKeys = Object.keys(variantsKeys).filter(
    (item) => query[item] !== '' && VARIANTS.includes(item),
  );

  const Deals = offers
    .filter(
      (offer) =>
        extractIsDealFromTags(offer.tags) &&
        matchedKeys.every(
          (item) =>
            tagToSelection(offer.tags)[item as keyof SelectionT] ===
            variants[item as keyof (SelectionT | ParsedUrlQuery)],
        ),
    )
    .sort((a, b) => b.price - a.price);

  const DealOffer = Deals[0];

  if (isEmpty(DealOffer)) return undefined;

  return DealOffer;
};
