import { useMemo, useState, useEffect } from 'react';
import Card from './Card';
import { MobileViewProps } from './types';

// Custom Hook to Detect Breakpoints
const useBreakpoint = () => {
  const [columns, setColumns] = useState(3);

  useEffect(() => {
    const updateColumns = () => {
      if (window.matchMedia('(min-width: 376px)').matches)
        setColumns(4); // Corresponds to xxs:grid-cols-4
      else setColumns(3); // Corresponds to grid-cols-3
    };

    updateColumns(); // Run initially
    window.addEventListener('resize', updateColumns); // Update on resize

    return () => window.removeEventListener('resize', updateColumns);
  }, []);

  return columns;
};

const MobileView = ({ categories }: MobileViewProps) => {
  const [seeMore, setSeeMore] = useState(false);
  const columns = useBreakpoint(); // Detect columns based on the breakpoint
  const maxCategories = columns === 4 ? 8 : 6; // Set the max categories dynamically

  const slicedCategories = useMemo(
    () => (seeMore ? categories : categories.slice(0, maxCategories)),
    [seeMore, categories, maxCategories],
  );

  const shouldShowSeeMore = categories.length > maxCategories;

  return (
    <div className="md:hidden">
      <div className="mt-6 grid grid-cols-3 gap-x-5 gap-y-8 xxs:grid-cols-4">
        {slicedCategories.map((category) => (
          <Card key={category.handle ?? category.pk} category={category} />
        ))}
      </div>
      {shouldShowSeeMore && (
        <button
          onClick={() => setSeeMore((prevState) => !prevState)}
          type="button"
          className="mx-auto mt-8 flex w-full items-center justify-center rounded-md border border-gray-700 py-2 text-sm xs:w-[250px]"
        >
          See {seeMore ? 'less' : 'more'}
        </button>
      )}
    </div>
  );
};

export default MobileView;
