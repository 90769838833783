import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Image from 'next/future/image';
import { VendorDetails } from '@lambda/apis/src/dynamoDb/types';
import cn from 'classnames';
import { strToHandle } from '@lambda/reebelo/src/commonHelpers';
import { isEmpty } from 'lodash-es';
import Link from 'next/link';
import Loading from '@/components/Loading/Loading';
import ReviewsStars from './ReviewsStars';
import TopSellerIcon from '@/public/icons/collection/top-seller.svg';
import SalesIcon from '@/public/icons/collection/sales.svg';
import ExpressShippingIcon from '@/public/icons/collection/express-shipping.svg';
import X from '@/public/icons/account/close.svg';
import { Breakpoints } from '@/settings/breakpoints';
import Slider from '@/components/slider/Slider';
import ReviewCard from './ReviewCard';
import { ReviewT, VendorReviewsProps, VendorReviewsT } from '@/settings/types';
import { VendorKey } from '@/settings/vendors/types';
import vendors from '@/settings/vendors';
import certified from '@/public/icons/product/certifiedBlack.svg';
import { generateVendorStars } from '@/lib/collections/helpers';

const BREAKPOINTS = {
  [Breakpoints.NONE]: {
    slidesPerView: 1.2,
    spaceBetween: 10,
  },
  [Breakpoints.MD]: {
    slidesPerView: 1.5,
    spaceBetween: 10,
  },
};

const Profile = ({
  vendor,
  vendorReviews,
  vendorTags,
  isCertifiedVendor,
}: {
  vendor: VendorDetails;
  vendorReviews: VendorReviewsT;
  vendorTags: { seller: string; sales: string; shipping: string };
  isCertifiedVendor: boolean;
}) => {
  const iconClassNames = 'flex flex-1 flex-col items-center gap-3';

  const updatedSummary = {
    ...vendorReviews.summary,
    stars: generateVendorStars(vendorReviews.summary),
  };

  return (
    <div className="relative flex flex-1 flex-col">
      <div className="flex flex-col items-center px-5 py-10">
        <h2 className="mb-2.5 text-xl font-medium">{vendor?.displayName}</h2>
        <ReviewsStars
          summary={updatedSummary}
          starSize={16}
          className="text-sm"
        />
        <div className="mt-6 flex w-full items-center text-xs font-light sm:text-sm">
          <div className={cn(iconClassNames)}>
            <Image
              src={isCertifiedVendor ? certified : TopSellerIcon}
              height={isCertifiedVendor ? 25 : 21}
              width={isCertifiedVendor ? 25 : 21}
              alt={vendorTags.seller}
            />
            <span>{vendorTags.seller}</span>
          </div>
          <div className={cn(iconClassNames)}>
            <Image
              src={SalesIcon}
              height={25}
              width={25}
              alt={vendorTags.sales}
            />
            <span>{vendorTags.sales}</span>
          </div>
          <div className={cn(iconClassNames)}>
            <Image
              src={ExpressShippingIcon}
              height={25}
              width={25}
              alt={vendorTags.shipping}
            />
            <span>{vendorTags.shipping}</span>
          </div>
        </div>
        {isCertifiedVendor && (
          <Link
            href={`/collections/vendor/${strToHandle(vendor?.company?.name)}`}
            key={vendor.displayName}
          >
            <a className="mt-2 flex w-full cursor-pointer items-center rounded-md  border-b border-gray-200 bg-gray-700 p-2">
              <div className="w-full text-center text-sm leading-normal text-white sm:text-base">
                <span>Visit {vendor.displayName} Store</span>
              </div>
            </a>
          </Link>
        )}
      </div>
      <div className="flex-1 bg-[#F9F9F9] px-5 py-5 lg:px-10">
        <h3 className="font-bold">
          {vendorReviews.reviews.length > 1 ? `Customer Reviews` : 'Top Review'}
        </h3>
        <h4 className="text-sm font-light text-gray-400">
          <span className="hidden sm:block">
            Most recent customer reviews on products delivered by this seller
          </span>
          <span className="sm:hidden">
            Recent reviews on products delivered by this seller
          </span>
        </h4>
        <div className="relative mt-5">
          <Slider breakpoints={BREAKPOINTS} className="relative">
            {vendorReviews.reviews.map((r: ReviewT) => (
              <ReviewCard
                key={r.id}
                review={r}
                type="vendor-reviews"
                offer={{
                  offerTitle: r.productName ?? '',
                }}
              />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default function VendorReviews(props: VendorReviewsProps) {
  const { open, setOpen, vendor, vendorReviews, totalSales } = props;

  const shouldShowExpressShipping = !(
    vendor?.dispatch?.expressMaxDays == null ||
    vendor?.dispatch?.expressMinDays == null
  );
  const vendorHandle = strToHandle(vendor?.company.name);
  const certifiedVendor = vendors[vendorHandle as VendorKey];
  const isCertifiedVendor = !isEmpty(certifiedVendor);

  const generateSellerTag = () => {
    if (isCertifiedVendor) return 'Direct From Brand';
    if ((vendor?.buybox?.vendorScore ?? 0) >= 104) return 'Top Seller';
    if (vendorReviews.summary.count >= 20) return 'Certified Seller';

    return 'New Seller';
  };

  const sellerTag = generateSellerTag();
  const salesTag = `${
    totalSales > 100 ? totalSales.toLocaleString() : '+100'
  } Sales`;
  const shippingTag = `${
    shouldShowExpressShipping ? 'Express' : 'Free'
  } Shipping`;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="absolute z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/40 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="min-[1536px]:max-w-[30vw] pointer-events-auto w-screen md:max-w-[75vw] lg:max-w-[50vw] xl:max-w-[40vw]">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="sticky top-0 z-10 bg-white px-5 py-5 shadow">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6">
                          Seller Details
                        </Dialog.Title>
                        <div className="ml-3 flex items-center">
                          <button
                            type="button"
                            className="relative flex items-center rounded-md bg-white transition duration-150 ease-in-out focus:outline-none"
                            onClick={() => setOpen(false)}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <div aria-hidden="true">
                              <Image
                                src={X}
                                height={12}
                                width={12}
                                alt="Close Modal"
                              />
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                    {vendorReviews ? (
                      <Profile
                        vendorTags={{
                          seller: sellerTag,
                          sales: salesTag,
                          shipping: shippingTag,
                        }}
                        vendor={vendor}
                        vendorReviews={vendorReviews}
                        isCertifiedVendor={isCertifiedVendor}
                      />
                    ) : (
                      <div className="flex h-full items-center justify-center">
                        <Loading size="md" />
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
