import useSWR from 'swr';
import type { ExpressShippingOfferResponse } from '@/api/express-shipping/offer';

export default function useExpressShippingOffer({
  vendor,
  categoryPk,
  isEnabled,
}: {
  vendor: string;
  categoryPk: string;
  isEnabled: boolean;
}) {
  const key = isEnabled
    ? `/api/express-shipping/offer?vendor=${encodeURIComponent(
        vendor,
      )}&categoryPk=${categoryPk}`
    : null;

  const response = useSWR<ExpressShippingOfferResponse>(key);

  return response.data?.shopifyOffer;
}
