import React, { Fragment, useMemo, useState } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import useSWR from 'swr';
import Image from 'next/future/image';
import Loading from '@/components/Loading/Loading';
import TradeInBanner from './TradeInBanner';
import SelectDevice from '@/components/buyback/v2/sell/SelectDevice';
import SelectVariant from '@/components/buyback/v2/sell/SelectVariant';
import {
  DeviceT,
  DeviceVariantT,
  PageType,
  SellDevicePageT,
  TradeInStep,
} from '@/lib/buyback/types';
import { useBuybackContext } from '@/lib/buyback/BuybackContext';
import Valuation from '@/components/buyback/v2/sell/Valuation';
import QuoteSummaryContent from '@/components/buyback/v2/sell/QuoteSummaryContent';
import { SegmentEvent } from '@/lib/analytics/events';
import { trackQuoteEvent } from '@/lib/analytics/trackQuote';
import PayoutInformation from '@/components/buyback/v2/sell/PayoutInformation';
import useCustomer from '@/lib/customer/useCustomer';
import { formatPhone } from '@/lib/buyback/helpers';
import infoIcon from '@/public/icons/buyback/thank-you/info.svg';
import { BuybackSalesChannel } from '@/components/buyback/common';
import { BuybackUSConfig } from '@/settings/configs/buyback/us/type';

const DEFAULT_HANDLES = ['electronic'];

const TradeInContent = ({ hideModal }: { hideModal: () => void }) => {
  const { resetQuote, summaryQuote, setQuote, quote, finalQuote } =
    useBuybackContext();
  const { customer } = useCustomer();
  const [handles, setHandles] = useState<string[]>(DEFAULT_HANDLES); // Default Handle
  const [deviceHandle, setDeviceHandle] = useState<string>('');

  // Get Buyback Listings
  const { data: listings } = useSWR<SellDevicePageT>(
    () => `/api/buyback/get-listings?handle=${handles[handles.length - 1]}`,
  );

  // Get Buyback Offers
  const { data: offers } = useSWR<{
    device: BuybackUSConfig;
    devices: DeviceT[];
    variants: DeviceVariantT[];
  }>(() =>
    deviceHandle
      ? `/api/buyback/get-offers-by-slug?handle=${deviceHandle}`
      : null,
  );

  const step = useMemo(() => {
    // Everything depends on the step. Do not change order.
    if (
      summaryQuote &&
      quote &&
      quote.isAccepted === true &&
      finalQuote &&
      !finalQuote.paymentMethod
    )
      return TradeInStep.PayoutInformation;
    if (summaryQuote && quote && quote.isAccepted === true)
      return TradeInStep.Summary;
    if (summaryQuote || (quote && quote.isAccepted === false))
      return TradeInStep.Valuation;
    if (!listings || (listings && deviceHandle && !offers))
      return TradeInStep.Loading;
    if (listings && !deviceHandle) return TradeInStep.SelectDevice;
    if (listings && offers) return TradeInStep.SelectVariant;

    return null;
  }, [listings, deviceHandle, offers, summaryQuote, quote]);

  // Reset TradeIn - Back to Step 0
  const resetTradeIn = () => {
    setHandles(DEFAULT_HANDLES);
    setDeviceHandle('');
    resetQuote();
  };

  const shouldShowBanner =
    step != null &&
    step === TradeInStep.SelectDevice &&
    listings &&
    listings.step === 0; // First Screen Only

  return (
    <div className="flex h-full flex-col overflow-y-auto">
      {shouldShowBanner && <TradeInBanner />}
      {/* Loading Screen  */}
      {step === TradeInStep.Loading ? (
        <div className="flex h-full flex-1 items-center justify-center">
          <Loading />
        </div>
      ) : (
        <Fragment>
          {/* Category + Brand + Device Selection Screen */}
          {step === TradeInStep.SelectDevice && listings && (
            <div className="p-5 text-sm">
              <SelectDevice
                device={listings.device}
                pageType={listings.pageType}
                tradeIn={{
                  previousStep:
                    handles.length > 1
                      ? () =>
                          setHandles((prevHandles) => {
                            const updatedHandles = [...prevHandles];

                            updatedHandles.pop();

                            return updatedHandles;
                          })
                      : undefined,
                  nextStep: (h: string) =>
                    listings.step < 2
                      ? setHandles((prevHandles) => [...prevHandles, h])
                      : setDeviceHandle(h),
                  setDeviceHandle,
                }}
              />
            </div>
          )}
          {/* Variant Selection Sreen */}
          {step === TradeInStep.SelectVariant && offers && (
            <div className="p-5 text-sm">
              <SelectVariant
                device={offers.device}
                variants={offers.variants}
                pageType={PageType.VARIANT}
                devices={offers.devices}
                tradeIn={{
                  previousStep: () => setDeviceHandle(''),
                }}
              />
            </div>
          )}
          {/* Valuation Screen Screen */}
          {step === TradeInStep.Valuation && (
            <div className="p-5 text-sm">
              <Valuation
                tradeIn={{
                  reset: resetTradeIn,
                }}
              />
            </div>
          )}
          {step === TradeInStep.PayoutInformation && (
            <div className="p-5 text-sm">
              <PayoutInformation
                email={customer?.email}
                phone={formatPhone(
                  customer?.phone ?? customer?.shippingAddress?.phone,
                )}
                tradeIn={{
                  back: () => {
                    if (quote) setQuote({ ...quote, isAccepted: false });
                  },
                  onSubmit: () => hideModal(),
                }}
              />
            </div>
          )}
          {/* Quote Summary  */}
          {step === TradeInStep.Summary && summaryQuote && (
            <div className="text-sm">
              <div className="bg-gray-200 p-5">
                <div className="flex h-[150px] w-full items-center justify-center">
                  <Image
                    src={summaryQuote.image}
                    height={200}
                    width={200}
                    className="h-full w-auto object-contain"
                    alt="Device"
                  />
                </div>
              </div>
              <div className="p-5">
                <div className="flex items-center gap-3 rounded-lg bg-[#ECFCF5] p-3 text-[#2C4A4B]">
                  <div className="h-6 w-6 shrink-0">
                    <Image
                      alt="Info"
                      src={infoIcon}
                      className="h-full w-full"
                      height={50}
                      width={50}
                    />
                  </div>
                  <span>
                    Make sure your quote is accurate. It is the only way we can
                    provide you with an accurate price estimation
                  </span>
                </div>
              </div>
              <div className="px-5 pb-5">
                <QuoteSummaryContent />

                <button
                  onClick={() => {
                    if (finalQuote) {
                      trackQuoteEvent(
                        SegmentEvent.BUYBACK_REJECTED,
                        {
                          ...finalQuote,
                          salesChannel: BuybackSalesChannel.TRADE_IN,
                        },
                        () => resetTradeIn(),
                      );
                    }
                  }}
                  type="button"
                  className="mt-5 flex h-10 w-full items-center justify-center rounded-lg bg-gray-700 font-bold text-white"
                >
                  Restart Quote
                </button>
              </div>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};

interface TradeInModalProps {
  showModal: boolean;
  hideModal: () => void;
}

export default function TradeInModal(props: Readonly<TradeInModalProps>) {
  const { showModal, hideModal } = props;

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={hideModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-30"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-30"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-700" onClick={hideModal} />
        </Transition.Child>
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex w-full md:w-[550px]">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-full">
                  <div className="relative flex h-full flex-col bg-white">
                    <div className="px-4 py-4 shadow sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-bold leading-6 text-gray-700 xs:text-lg"
                        >
                          Trade-in
                        </Dialog.Title>
                        <button
                          onClick={hideModal}
                          type="button"
                          className="btn-close opacity-100 md:h-3 md:w-3"
                          aria-label="Close"
                        />
                      </div>
                    </div>
                    {/* Content  */}
                    {showModal && <TradeInContent hideModal={hideModal} />}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
