import { Breakpoints } from '@/settings/breakpoints';

export const breakpoints = {
  [Breakpoints.NONE]: {
    slidesPerView: 3.5,
    slidesPerGroup: 1,
    spaceBetween: 15,
  },
  [Breakpoints.XS]: {
    slidesPerView: 4.5,
    slidesPerGroup: 1,
    spaceBetween: 15,
  },
  [Breakpoints.SM]: {
    slidesPerView: 5.5,
    slidesPerGroup: 1,
    spaceBetween: 15,
  },
  [Breakpoints.MD]: {
    slidesPerView: 5.5,
    slidesPerGroup: 1,
    spaceBetween: 15,
  },
  [Breakpoints.LG]: {
    slidesPerView: 6,
    slidesPerGroup: 6,
    spaceBetween: 20,
  },
  [Breakpoints.XL]: {
    slidesPerView: 7,
    slidesPerGroup: 7,
    spaceBetween: 20,
  },
};
export const cartBreakpoints = {
  [Breakpoints.NONE]: {
    slidesPerView: 3.5,
    slidesPerGroup: 1,
    spaceBetween: 15,
  },
  [Breakpoints.XS]: {
    slidesPerView: 4.5,
    slidesPerGroup: 1,
    spaceBetween: 15,
  },
  [Breakpoints.SM]: {
    slidesPerView: 5.5,
    slidesPerGroup: 1,
    spaceBetween: 15,
  },
  [Breakpoints.MD]: {
    slidesPerView: 6.5,
    slidesPerGroup: 1,
    spaceBetween: 15,
  },
  [Breakpoints.LG]: {
    slidesPerView: 7,
    slidesPerGroup: 7,
    spaceBetween: 20,
  },
  [Breakpoints.XL]: {
    slidesPerView: 7,
    slidesPerGroup: 7,
    spaceBetween: 20,
  },
};
export const thankYouBreakpoints = {
  [Breakpoints.NONE]: {
    slidesPerView: 3.5,
    slidesPerGroup: 1,
    spaceBetween: 15,
  },
  [Breakpoints.XS]: {
    slidesPerView: 4.75,
    slidesPerGroup: 1,
    spaceBetween: 15,
  },
  [Breakpoints.SM]: {
    slidesPerView: 5.75,
    slidesPerGroup: 1,
    spaceBetween: 15,
  },
  [Breakpoints.MD]: {
    slidesPerView: 6.75,
    slidesPerGroup: 1,
    spaceBetween: 15,
  },
  [Breakpoints.LG]: {
    slidesPerView: 4,
    slidesPerGroup: 5,
    spaceBetween: 20,
  },
  [Breakpoints.XL]: {
    slidesPerView: 6,
    slidesPerGroup: 6,
    spaceBetween: 20,
  },
};
