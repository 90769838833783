import { useState } from 'react';
import Image from 'next/future/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import cn from 'classnames';
import Loading from '@/components/Loading/Loading';
import { priceFormater } from '@/settings/index';
import checkIcon from '@/public/icons/buyback/check.svg';
import truckIcon from '@/public/icons/buyback/faq/truck.svg';
import shieldIcon from '@/public/icons/buyback/faq/shield.svg';
import freeReturnIcon from '@/public/icons/buyback/free-return.svg';
import { useBuybackContext } from '@/lib/buyback/BuybackContext';
import { DeviceT } from '@/lib/buyback/types';
import { trackQuoteEvent } from '@/lib/analytics/trackQuote';
import { SegmentEvent } from '@/lib/analytics/events';
import { BuybackSalesChannel } from '../../common';

const NEXT_STEPS = [
  'Fill in your contact details and print your pre-paid shipping label',
  'Prepare your phone for shipping and drop it at the closest location',
  'Once the device has been inspected we will issue your payment',
];

const FEATURES = [
  { title: 'Free shipping', icon: truckIcon },
  { title: 'Expert data erasure', icon: shieldIcon },
  { title: 'Free returns', icon: freeReturnIcon },
];

const AcceptOffer = ({
  quote,
  tradeIn,
}: {
  quote: DeviceT & { isAccepted: boolean };
  tradeIn?: {
    reset: () => void;
  };
}) => {
  const router = useRouter();
  const { finalQuote, setQuote } = useBuybackContext();

  const [loading, setLoading] = useState<boolean>(false);
  const { query } = useRouter();

  const handle = query.handle as string;

  const onAcceptOffer = async () => {
    setQuote({ ...quote, isAccepted: true });

    if (!tradeIn) {
      setLoading(true);
      router.push(`/buyback/${handle}/personal-info`);
    }
  };

  return (
    <div className="flex flex-col items-center text-center">
      <h4
        className={cn({
          'text-lg lg:text-xl': !tradeIn,
          'text-base': tradeIn,
        })}
      >
        Valuation complete!
      </h4>
      <h3
        className={cn('text-2xl font-bold leading-tight ', {
          'mt-4 lg:text-3xl xl:text-4xl': !tradeIn,
          'mt-2': tradeIn,
        })}
      >
        Your device is worth <br />{' '}
        {priceFormater.short(quote['basePrice-used'])}
      </h3>
      {tradeIn && (
        <div className="mt-8">
          <div className="h-32">
            <Image
              alt={quote.model}
              src={quote.image}
              className="h-full w-full object-contain"
              height={300}
              width={500}
              quality={100}
            />
          </div>
          <h4 className="mt-4 text-base font-bold">
            {quote.model.split(' - ')[0]}
          </h4>
        </div>
      )}
      <div className="mt-4 flex h-full w-full flex-row items-start justify-center gap-4 rounded-lg bg-gray-300 p-4">
        {FEATURES.map((feature, index) => (
          <>
            <div
              className={cn(
                'flex flex-1 flex-col items-center justify-center gap-2',
                { 'xl:flex-row': !tradeIn },
              )}
              key={`content_${feature}`}
            >
              <div className="flex h-5 w-5 shrink-0 items-center justify-center">
                <Image
                  alt="Check"
                  src={feature.icon}
                  height={50}
                  width={50}
                  quality={100}
                  className="h-full w-full object-contain"
                />
              </div>
              <span>{feature.title}</span>
            </div>
            {/* Seperator  */}
            {index < FEATURES.length - 1 && (
              <div
                className="w-[1px] self-stretch bg-gray-700/10"
                key={`seperator_${feature}`}
              />
            )}
          </>
        ))}
      </div>
      <div className="mt-8 w-full text-left">
        <h3 className="text-lg font-bold">Next steps to sell your device</h3>
        <div className="mt-2 flex flex-col gap-2">
          {NEXT_STEPS.map((step) => (
            <div className="flex gap-2" key={step}>
              <div className="flex h-4 w-4 shrink-0 items-center justify-center">
                <Image
                  alt="Check"
                  src={checkIcon}
                  height={50}
                  width={50}
                  quality={100}
                  className="h-full w-auto object-contain"
                />
              </div>
              {step}
            </div>
          ))}
        </div>
      </div>
      <div
        className={cn({
          'mt-8 flex w-full gap-4': !tradeIn,
          'mt-8 flex w-full flex-col-reverse gap-2': tradeIn,
        })}
      >
        <button
          onClick={() => {
            if (finalQuote) {
              trackQuoteEvent(
                SegmentEvent.BUYBACK_REJECTED,
                {
                  ...finalQuote,
                  salesChannel: tradeIn
                    ? BuybackSalesChannel.TRADE_IN
                    : BuybackSalesChannel.BUYBACK,
                },
                () =>
                  tradeIn
                    ? tradeIn.reset()
                    : router.push(`/buyback/${handle}/device`),
              );
            }
          }}
          type="button"
          className={cn({
            'flex h-12 flex-1 items-center justify-center rounded-lg border border-gray-700 p-3 hover:underline':
              !tradeIn,
            'p-3 underline transition duration-150 ease-in-out hover:opacity-100 md:opacity-50':
              tradeIn,
          })}
        >
          Start Over
        </button>

        <button
          onClick={() => onAcceptOffer()}
          className="flex h-12 flex-1 items-center justify-center rounded-lg border border-gray-700 bg-gray-700 p-3 text-white hover:underline disabled:cursor-not-allowed disabled:opacity-50"
          disabled={loading}
        >
          {loading ? <Loading /> : 'Accept Offer'}
        </button>
      </div>
    </div>
  );
};

const Valuation = ({
  tradeIn,
}: {
  tradeIn?: {
    reset: () => void;
  };
}) => {
  const { quote, isLoading } = useBuybackContext();
  const router = useRouter();
  const { handle } = router.query;

  if (isLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <Loading />
      </div>
    );
  }

  if (!quote) {
    return (
      <div>
        <h3 className="text-lg font-bold">
          Sorry, this product isn't in our catalog
        </h3>
        <p className="mt-2.5">
          We regret to inform you that we are unable to purchase the selected
          device in the given condition at the moment. If you think you made a
          mistake, please review the replies to the questionnaire again.
        </p>
        <p className="mt-2.5">
          Otherwise, our certified partners update their catalog constantly and
          your device might be available in a near future.{' '}
        </p>
        <p className="mt-2.5">We hope to see you again soon!</p>
        {tradeIn ? (
          <button
            type="button"
            className="mt-5 h-10 rounded-lg bg-gray-700 px-10 font-bold text-white"
            onClick={() => tradeIn.reset()}
          >
            Redo Quote
          </button>
        ) : (
          <Link href={`/buyback/${handle}/device`}>
            <button
              type="button"
              className="mt-5 h-10 rounded-lg bg-gray-700 px-10 font-bold text-white"
            >
              Redo Quote
            </button>
          </Link>
        )}
      </div>
    );
  }

  if (quote) return <AcceptOffer quote={quote} tradeIn={tradeIn} />;

  return null;
};

export default Valuation;
