import Card from './Card';
import { DesktopViewProps } from './types';

const DesktopView = ({ categories, small = false }: DesktopViewProps) => (
  <div className="mt-6 hidden gap-x-5 gap-y-8 md:grid md:grid-cols-5 lg:grid-cols-7">
    {categories.map((category) => (
      <Card
        key={category.handle ?? category.pk}
        category={category}
        small={small}
      />
    ))}
  </div>
);

export default DesktopView;
