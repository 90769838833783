import {
  RC_DURATION,
  ReebeloConditions,
  ReebeloCurrencies,
  ReebeloStoreT,
} from '@lambda/reebelo';
import {
  Cart,
  ProductVariant,
} from '@lambda/commons/apis/shopify/types/storefront';
import { SearchOffers } from '@lambda/commons/apis/elasticsearch/types';
import { WishlistDetails } from '@lambda/apis/src/dynamoDb/types';
import { CheckoutT, CustomerOrderT } from '@lambda/apis/src/checkout/types';
import {
  CustomerSubscriptionStatus,
  CustomerSubscriptionSource,
} from '@lambda/apis/src/customer/enum';
import { Customer } from '@lambda/apis/src/customer/types';
import { PaymentProcessor } from '@lambda/apis/src/checkout/enum';
import { ReplacedProduct } from '../collections/catalogue/helpers';
import { ProductDetails } from '@/components/products/PurchaseLinks';
import { SearchKeys } from '../search-helpers';
import { SegmentEvent } from './events';
import { EssentialSearchOffers } from '../collection-search-helpers';
import { TopDealProduct } from '@/components/collections/hotDealsV3/types';
import { OffersT } from '../checkout/types';

export type IdentifyCustomerRequest = {
  userId?: string;
  properties: {
    first_name?: string;
    last_name?: string;
    email?: string;
    phone?: string;
    reebelo_id?: string;
    shopify_id?: string;
    store?: ReebeloStoreT;
    email_subscribe?: CustomerSubscriptionStatus;
    subscription_source?: CustomerSubscriptionSource;
    checkout_url?: string;
    address?: {
      street: string;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
  };
};

export enum ProductSource {
  PDP = 'pdp',
  SPECS = 'specs',
  WARRANTY = 'warranty',
  FASTER_DELIVERY = 'faster-delivery',
}

export type ProductEventProperties = {
  offer_id?: string;
  product_id?: string;
  variant_id?: string;
  offer_pk?: string;
  sku?: string;
  psku?: string;
  name?: string;
  category?: string;
  brand?: string;
  color?: string;
  condition?: ReebeloConditions;
  price?: number;
  compare_price?: number;
  currency?: ReebeloCurrencies;
  quantity?: number;
  images?: string[];
  store?: ReebeloStoreT;
  source?: ProductSource;
  url?: string;
  timestamp?: string;
  vendor?: string;
  battery?: {
    upgrade_pct?: number;
    upgrade_price?: number;
  };
};

type ProductListEventProperties = {
  list_id?: string;
  category?: string;
  products: ProductEventProperties[];
};

export type ProductListFilterSort = {
  type: string;
  value: string;
};

export type ProductListFilteredEventProperties = ProductListEventProperties & {
  filters?: ProductListFilterSort[];
  sorts?: ProductListFilterSort[];
};

export type ProductListTrackRequest = {
  event: SegmentEvent;
  offers?: SearchOffers[] | TopDealProduct[];
  collections?: EssentialSearchOffers[];
  limit: number;
  qs?: Record<SearchKeys, string> | { [k: string]: string };
};

export type ReebeloCareTrackRequest = {
  entry_point: 'Product Page' | 'Cart' | 'Cart Pop-up' | 'Account';
  reebelocare_id: number | undefined;
  device_psku: string;
  device_vendor: string;
  device_sku: string;
  duration: RC_DURATION | undefined;
  previous_order_number?: string;
};

export type ProductTrackRequest = {
  offer?: ReplacedProduct;
  details?: ProductDetails;
  variant?: ProductVariant;
  variantAttributes?: Record<string, string>[];
  wishlist?: WishlistDetails;
  properties?: ProductEventProperties;
  customer?: Customer;
};

export type CartEventProperties = {
  store: ReebeloStoreT;
  cart_id: string;
  products: ProductEventProperties[];
};

export type CheckoutEventProperties = {
  store?: ReebeloStoreT;
  order_id?: string;
  checkout_id?: string;
  email?: string;
  phone?: string;
  affiliation?: string;
  value?: number;
  revenue?: number;
  shipping?: number;
  tax?: number;
  duties?: number;
  discount?: number;
  coupon?: string;
  currency?: ReebeloCurrencies;
  products?: ProductEventProperties[];
  new_checkout?: boolean;
  payment_processor?: PaymentProcessor;
};

export type CheckoutTrackRequest = {
  cart?: Cart;
  checkout?: CheckoutT;
  order?: CustomerOrderT;
  properties?: CheckoutEventProperties;
  offers?: OffersT;
};
