export const urlWithUtm = (url: string, source: string) => {
  const utmParams = new URLSearchParams({
    utm_source: source,
  });

  // Check if the URL already contains query parameters
  const separator = url.includes('?') ? '&' : '?';

  return `${url}${separator}${utmParams}`;
};
